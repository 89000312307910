import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
function dispatchButtonLoading(isLoading) {
  if (__IS_SSR__) return;
  var event = new CustomEvent("BUTTON_LOADING_EVENT", {
    detail: {
      isLoading: isLoading
    }
  });
  window.dispatchEvent(event);
}
function useButtonLoading() {
  var _useState = useState(false),
    buttonLoading = _useState[0],
    setButtonLoading = _useState[1];
  var location = useLocation();
  useEffect(function () {
    // Event listener for "FOOTER_LOADING_EVENT"
    var handleButtonLoadingEvent = function handleButtonLoadingEvent(event) {
      var customEvent = event;
      setButtonLoading(customEvent.detail.isLoading);
    };
    window.addEventListener("BUTTON_LOADING_EVENT", handleButtonLoadingEvent);

    // Cleanup the event listener
    return function () {
      window.removeEventListener("BUTTON_LOADING_EVENT", handleButtonLoadingEvent);
    };
  }, []);
  useEffect(function () {
    // Reset the loading state when location pathname changes
    dispatchButtonLoading(false);
  }, [location.pathname]);
  return buttonLoading;
}
export { dispatchButtonLoading };
export default useButtonLoading;