import { useLocation, useSearchParams } from "react-router-dom";
import CardBody from "./CardBody/CardBody";
import CardFooter from "./CardFooter/CardFooter";
import CardHeader from "./CardHeader/CardHeader";
import stl from "./UnbxdCard.module.css";
import { unbxdAnalytics } from "@/redux/actions/unbxdActions";
import { serverUrl } from "@globalConfig";
import useHelpers from "@hooks/useHelpers";
import { useSession } from "@/_MiniApps/auth/AuthContext";
import { useCarCompareContext } from "@/_MiniApps/CarCompare/CarCompare";
import AddCarToCompare from "@/_MiniApps/CarCompare/Components/AddCarToCompare";

const UnbxdCard = ({
  post,
  showGrantedPopup,
  metaData,
  isEager,
  isCustomDesign,
  hideFooterDeskMob,
  className,
  hideFavIcon,
  rank,
  page,
  isRecommendations,
  hideGrantedLabel,
  hideMarkitingLabel,
  hideDiscountLabel,
  TheImage = null,
  CustomTag = "h2",
  is_rec = false,
  HasImageSlider = false,
  slideOnlyOnArr = false,
  isAnchor = false,
  fromHomePage = false,
  referralLocation = null,
  sectionType = 'listing',
  onClick = () => { },
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { createUrl } = useHelpers();
  const isBrowseSearch = location.pathname.includes("/autos") || fromHomePage;
  const { isLoggedIn } = useSession();
  const { isCarIncluded: isCarIncludedToCompare,isCompareBarAllowed } = useCarCompareContext();
  if (location.pathname.includes("/deal")) {
    referralLocation = "offerpage";
  }

  const listing_type = referralLocation ? referralLocation : searchParams.get("text")
    ? "search"
    : isBrowseSearch
      ? "browse"
      : "filter";

  let modifiedLink = createUrl(post?.product_url);
  let toLink = `${modifiedLink}${isRecommendations ? `&rank=${rank}` : ""}${page && rank && listing_type
      ? `#page_number=${page}&product_position=${rank}&listing_type=${listing_type}&logged_in=${isLoggedIn}`
      : ""
  }${is_rec ? `#is_rec=1&product_position=${rank}&logged_in=${isLoggedIn}` : ""}`;
  toLink = `${toLink}${toLink.includes("#")?"&":"#"}${sectionType ? `section_type=${sectionType}` : ""}`;

  return (
    <div
      state={{ screen_view_referral: "referralLink" }}
      className={[

        stl.wrapper, className ?? undefined,
        isCarIncludedToCompare(post.id)&&isCompareBarAllowed() ? stl.blueBorder : undefined
      ].join(" ")}
      onClick={() => {
        const params = {
          action: "click",
          query: searchParams.get("text") || "",
          pid: post.uniqueId,
          url: serverUrl + location.pathname + location.search,
          page: metaData?.category_path || "",
          page_type: metaData?.category_path ? "BOOLEAN" : "",
        };

        if (!isBrowseSearch || !metaData?.category_path) {
          // delete params.query;
          delete params.page;
          delete params.page_type;
        }

        if (isBrowseSearch && metaData?.category_path) {
          delete params.query;
        }
        unbxdAnalytics(params);
        onClick && onClick()
      }}
    >
      <CardHeader
        rank={rank}
        to_link={toLink}
        post={post}
        isEager={isEager}
        isCustomDesign={isCustomDesign}
        hideFavIcon={hideFavIcon}
        hideMarkitingLabel={hideMarkitingLabel}
        hideDiscountLabel={hideDiscountLabel}
        TheImage={TheImage}
        HasImageSlider={HasImageSlider}
        slideOnlyOnArr={slideOnlyOnArr}
        isAnchor={isAnchor}
      />
      <CardBody
        to_link={toLink}
        post={post}
        showGrantedPopup={showGrantedPopup}
        isCustomDesign={isCustomDesign}
        hideGrantedLabel={hideGrantedLabel}
        CustomTag={CustomTag}
        isAnchor={isAnchor}
      />
      {
        !hideFooterDeskMob && (
          <CardFooter
            to_link={toLink}
            tags={post.tags}
            isCustomDesign={isCustomDesign}
            isAnchor={isAnchor}
          />
        )
      }
      <AddCarToCompare car={post}/>
    </div >
  );
};

export default UnbxdCard;
