import arWebsiteSettings from "../../../data/website-settings/ar.json";
import enWebsiteSettings from "../../../data/website-settings/en.json";
import all_flags from "../../../data/feature-flags/all.json";
import useLocalStorage from "@hooks/useLocalStorage";
import useCookieClient from "@hooks/useCookieClient";
export function websiteConfig(lang) {
  if (lang === "ar") {
    return arWebsiteSettings;
  }
  return enWebsiteSettings;
}
export function checkFeatureFlag(flag) {
  var _useLocalStorage = useLocalStorage(false),
    getLocalStorage = _useLocalStorage.getLocalStorage;
  var _useCookieClient = useCookieClient(),
    getCookieClient = _useCookieClient.getCookieClient;
  if (__IS_CSR__ && window.location.href.includes(flag)) {
    var urlParams = new URLSearchParams(window.location.search);
    var myParam = urlParams.get(flag);
    return myParam !== "false";
  }
  if (__IS_CSR__ && getLocalStorage(flag)) {
    return Boolean(Number(getLocalStorage(flag))) || getLocalStorage(flag) === "true";
  }
  if (__IS_CSR__ && getCookieClient(flag)) {
    return Boolean(Number(getCookieClient(flag)));
  }
  return all_flags[flag] || false;
}

/**
 * Check if the AB flag is enabled
 * in case of Undefined, it means the flag is not set
 * @param flag
 */
export function checkABFlag(flag) {
  var _useLocalStorage2 = useLocalStorage(),
    getLocalStorage = _useLocalStorage2.getLocalStorage;
  var _useCookieClient2 = useCookieClient(),
    getCookieClient = _useCookieClient2.getCookieClient;
  if (__IS_CSR__ && window.location.href.includes(flag)) {
    var urlParams = new URLSearchParams(window.location.search);
    var myParam = urlParams.get(flag);
    return myParam !== "false";
  }
  if (__IS_CSR__ && getLocalStorage(flag)) {
    return Boolean(Number(getLocalStorage(flag)));
  }
  if (__IS_CSR__ && getCookieClient(flag)) {
    return Boolean(Number(getCookieClient(flag)));
  }
  if (__IS_CSR__ && document.body.classList.contains(flag)) {
    return true;
  }
  return undefined;
}