import { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { loadableReady } from "@loadable/component";
import {createRoot} from "react-dom/client";
import App from "./App";
import extractedRoutes from "./routes/routes";
import "./styles/fontStyle.css";

import Loader from "@componentsShared/Loader/Loader";
import "./Polyfills";
import { external_first_interaction } from "@hooks/useIsFirstInteraction";
import { PostHogProvider } from "posthog-js/react";
import { disable_posthog, posthog_apihost, posthog_key } from "@globalConfig";
import { AuthProvider } from "@/_MiniApps/auth/AuthContext";
import { CarCompareProvider } from "./_MiniApps/CarCompare/CarCompare";
import { RecentlyViewedProvider } from "./_MiniApps/RecentlyViewedContext/RecentlyViewedContext";
import {PostDetailsContextProvider} from "@/common/Contexts/PostDetailsContext";
import {CheckoutOrderContextProvider} from "@/common/Contexts/CheckoutOrderContext";
import {AribFinanceContextProvider} from "@/common/Contexts/AribFinanceContext";
import CurrencyFontProvider from "@/_MiniApps/CurrencyFont/CurrencyFontProvider";




const routes = extractedRoutes();
const posthogOptions = {
  api_host: posthog_apihost,
  person_profiles: "always",
  enable_recording_console_log: true,
  session_recording: {
    maskAllInputs: true,
    maskInputFn: (text, element) => {
      /** @type {HTMLElement} */
      if (!element?.classList.contains("mysr-form-input")) {
        return text;
      }
      return "*".repeat(text.length);
    },
  },
  maskInputOptions: {
    password: true,
  },
};
const AppContainer = () => {
  useEffect(() => {
    external_first_interaction();
  }, []);
  return (
    <Suspense fallback={<Loader />}>

      <BrowserRouter>
          {!disable_posthog && (
            <PostHogProvider apiKey={posthog_key} options={posthogOptions}>
              <AuthProvider>
                <CarCompareProvider>
                  <RecentlyViewedProvider>
                  <PostDetailsContextProvider >
                    <CheckoutOrderContextProvider>
                        <AribFinanceContextProvider>
                        <CurrencyFontProvider>
                      <App routes={routes}/>
                        </CurrencyFontProvider>
                        </AribFinanceContextProvider>
                    </CheckoutOrderContextProvider>
                  </PostDetailsContextProvider>
                  </RecentlyViewedProvider>
                </CarCompareProvider>
              </AuthProvider>
            </PostHogProvider>
          )}
          {disable_posthog && (
            <AuthProvider>
              <CarCompareProvider>
                <RecentlyViewedProvider>
                <PostDetailsContextProvider>
                  <CheckoutOrderContextProvider>
                      <AribFinanceContextProvider>
                      <CurrencyFontProvider>
                    <App routes={routes}/>
                      </CurrencyFontProvider>
                      </AribFinanceContextProvider>
                  </CheckoutOrderContextProvider>
                </PostDetailsContextProvider>
                </RecentlyViewedProvider>
              </CarCompareProvider>
            </AuthProvider>
          )}
        </BrowserRouter>

    </Suspense>
  );
};

loadableReady(() => {
  const root = createRoot(document.getElementById('root'));
  root.render(<AppContainer/>);
});

if (module.hot) {
  module.hot.accept();
}
